<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>应用管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>

    </el-breadcrumb>

    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="轮播图" name="1">
        <div style="color: darkgray;"> 轮播图默认四张，您可切换到相应的轮播图设置效果</div>
      </el-collapse-item>
    </el-collapse>
    <!-- 搜索选择开始 -->
    <el-card class="box-card">
      <el-row style="display: flex;justify-content: flex-start;">
        <el-select v-model="value" placeholder="选择展示位" @change="typechang" size="medium">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        </el-input>
      </el-row>
    </el-card>
    <!-- 搜索选择结束 -->

    <div style="padding: 20px;font-size: 20px;">
      <span> 轮播图展示（点击左右箭头切换）</span>
    </div>
    <el-carousel trigger="click" height="30vh" :autoplay='false' @change="change" style="margin-top: 20px;">
      <el-carousel-item v-for="(item, key) in bannerlist" :key="key"
        style="width: 400px;height: 300px;overflow: hidden;">
        <el-image :src="item.img" :fit="fit" style="height: 300px;">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </el-carousel-item>
    </el-carousel>



    <el-form ref="form" :model="form" label-width="120px">




      <el-form-item label="应用名称" prop="appname">
        <span> {{appname}}</span>
      </el-form-item>

      <el-form-item label="图片排序" prop="index">
        <span> {{index+1}}</span>
      </el-form-item>
      <el-form-item label="替换图片" prop="img">


        <el-input type="text" v-model="form.img" placeholder="可以点击下面按钮上传图片也可以直接输入图片地址"></el-input>
        <div style="display: flex;justify-content: space-between;margin: 10px;">
          <el-upload ref="upload" class="" action="none" :limit="1" :file-list="fileList" list-type="picture"
            :show-file-list="false" :http-request="handleFileUpload"><el-button type="success"
              size='medium'>上传图片</el-button>
          </el-upload>
          <el-button type="primary" size='mini'>复制地址</el-button>
          </el-upload>
        </div>

      </el-form-item>





      <el-form-item label="图片名称" prop="desc">
        <el-input type="text" v-model="form.name" placeholder="输入图片名称以便在素材库查找"></el-input>
      </el-form-item>




      <el-form-item label="跳转链接" prop="url">
        <el-input type="text" v-model="form.url" placeholder="如果需要跳转请输入,否则无需输入"></el-input>
      </el-form-item>

      <el-form-item label="图片说明" prop="desc">
        <el-input type="textarea" v-model="form.desc" placeholder="输入关键字说明以便在素材库查找"></el-input>
      </el-form-item>






      <div style="display: flex;justify-content: flex-end;">
        <el-button type="primary" @click="onSubmit">提 交</el-button>
        <el-button>重 置</el-button>
      </div>

      </el-form-item>
    </el-form>

  </div>

  </div>
</template>

<script>
  import {
    Loading
  } from 'element-ui';
  export default {
    data() {
      return {
        activeNames: ['1'],
        appname:this.$appname,
        value: '首页',
        options: [{
            value: '首页',
            label: '首页'
          },

          {
            value: '我的',
            label: '我的'
          }
        ],
        bannerlist: [{
            id: '1',
            img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            index: 1
          },
          {
            id: '2',
            img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            index: 2
          },
          {
            id: '3',
            img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            index: 3
          },

          {
            id: '4',
            img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            index: 4
          },

        ],
        fileList: [],
        index: 0,
        fit: 'cover',
        src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        form: {
          index: 1,
          appname: this.$appname,
          img: '',
          name: '',
          url: '',
          desc: '',
          type: '首页'
        },
      }
    },


    async created() {
      const formData = new FormData()
      formData.append('appname', this.form.appname)
      formData.append('type', this.form.type)
      const result = await this.$http.post('/yingyong/get_banner', formData)
      if (result.data.data != '') {
        this.bannerlist = result.data.data;
        this.form.img = this.bannerlist[0].img
        this.form.name = this.bannerlist[0].name
        this.form.desc = this.bannerlist[0].desc
        this.form.url = this.bannerlist[0].url
      }

      console.log(this.bannerlist);


    },






    methods: {

      handleChange(e) {
        console.log(e);
      },


      async onSubmit() {
        this.$confirm('此操作将修改当前轮播图参数, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(this.form);
          this.loadingInstance = Loading.service({
            // 动画中的文字
            text: '数据正在提交，请稍候！',
            lock: true, // 是否锁屏
            // 要加载动画的容器
            target: '.app-container'
          });
          const formData = new FormData()
          formData.append('appname', this.appname)
          formData.append('desc', this.form.desc)
          formData.append('name', this.form.name)
          formData.append('url', this.form.url)
          formData.append('index', this.index + 1)
          formData.append('img', this.form.img)
          formData.append('type', this.form.type)
          this.post(formData)
          setTimeout(() => {
            this.loadingInstance.close();
          }, 500)
        }).catch(() => {

        });



      },


      async post(formData) {
        const result = await this.$http.post('/yingyong/banneradd', formData)
        console.log(result);
        switch (result.data.data.message) {
          case '提交成功':
            break;
          case '无操作权限':
            break;
          default:
            this.$message(result.data.message);
            break;
        }

      },





      change(e) {
        console.log(e);
        this.index = e
        this.form.index = this.index + 1
        this.form.img = this.bannerlist[e].img
        this.form.name = this.bannerlist[e].name
        this.form.desc = this.bannerlist[e].desc
        this.form.url = this.bannerlist[e].url

      },

      async typechang(e) {
        console.log(e);
        this.form.type = e

        const formData = new FormData()
        formData.append('appname', this.form.appname)
        formData.append('type', this.form.type)

        const result = await this.$http.post('/yingyong/get_banner', formData)
        this.bannerlist = result.data.data;
        console.log(this.bannerlist);


        this.form.index = this.index + 1
        this.form.img = this.bannerlist[this.index].img
        this.form.name = this.bannerlist[this.index].name
        this.form.desc = this.bannerlist[this.index].desc
        this.form.url = this.bannerlist[this.index].url





      },

      handleClick(tab, event) {
        console.log(this.activeName);
      },
      // 上传前的钩子
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!')
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG && isLt2M
      },
      // 头像自定义上传
      async handleFileUpload(param) {
        // 3. 生成动画
        this.loadingInstance = Loading.service({
          // 动画中的文字
          text: '文件上传中,请稍等！',
          lock: true, // 是否锁屏
          // 要加载动画的容器
          target: '.app-container'
        });
        //传参进来，同时定义一个formdata对象
        const formData = new FormData()
        formData.append('name', 'file')
        formData.append('file', param.file)

        const {
          data: res
        } = await this.$http.post('/imgupload/save', formData)
        console.log(res)
        console.log(res.result);
        if (res.errorCode == '200') {
          this.loadingInstance.close();
          this.form.img = this.$imgapi + res.result
          this.$message('图片上传成功');
          this.src = this.$imgapi + res.result
        }
        this.fileList = []
      },


    },
  }
</script>

<style>
  .el-carousel__item h3 {
    color: #ffffff;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
  }
</style>