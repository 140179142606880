import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './api'
import './plugins/element.js'
import '/src/assets/fonts/iconfont.css'
import '/src/assets/graceui/css/graceUI.css'
import '@/assets/css/global.css'


// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';


import axios from 'axios'
Vue.prototype.$http = axios 
// axios.defaults.baseURL = 'https:/www.csgyzc.cn/cishangapi/'
// axios.defaults.baseURL = '/api'
Vue.prototype.$tel = window.sessionStorage.getItem('tel')
Vue.prototype.$api = api

Vue.prototype.$imgapi = 'https://www.csgyzc.cn' + '/'
Vue.prototype.$appname =  window.sessionStorage.getItem('appname')
Vue.prototype.$logo =  window.sessionStorage.getItem('logo')


// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// require styles 富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import store from './store'

// 注册富文本编辑器组件为全局组件
Vue.use(VueQuillEditor)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
