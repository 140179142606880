export default [{
		"id": 10,
		"username": "万讯云&永哥",
		"tel": '15080000608',
		"avatar": "https://go.cdn.heytea.com/2020/02/26/tmp/f5d557b627b640838d0c324bd96eabfb.jpg",
    "vip":2,
    "last_logintime":'2023.5.18',
    "registetime":'2023.1.18'
	}
]
