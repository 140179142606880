<template>
  　　<div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目添加</el-breadcrumb-item>
    </el-breadcrumb>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="内容添加提示" name="1">
        <div style="color: darkgray;"> 为保证界面设计的美观及统一，您可采用第三方插件对文本进行编辑，之后复制到下列文本框即可</div>
      </el-collapse-item>
    </el-collapse>
    <el-form ref="form" :model="form" size="medium" label-width="120px" :label-position="labelPosition">
      <div style="height: 20px;"> </div>
      <el-form-item label="菜单名称">
        <el-select v-model="form.name" prop="name" placeholder="请选择要发布的领域" @change="change('name',form.name)">
          <el-option :label="item.name" :value="item.name" v-for="(item, key) in menulist" :key="key"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="标题" prop="title" @change="change('title',form.title)">
        <el-input type="text" v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>

      <el-form-item label="小标题" prop="smalltitle">
        <el-input type="text" v-model="form.smalltitle" placeholder="请输入标题"></el-input>
      </el-form-item>


      <el-form-item label="预算金额(元)" prop="yusuan">
        <el-input type="number" v-model="form.yusuan" placeholder="预算金额"></el-input>
      </el-form-item>


      <el-form-item label="已募集金额(元)" prop="yimuji">
        <el-input type="number" v-model="form.yimuji" placeholder="已募集金额"></el-input>
      </el-form-item>


      <el-form-item label="捐款人次(人)" prop="juankuanrenci">
        <el-input type="number" v-model="form.juankuanrenci" placeholder="捐款人次"></el-input>
      </el-form-item>


 <!--     <el-form-item label="发起机构" prop="faqijigou">
        <el-input type="text" v-model="form.faqijigou" placeholder="发起机构"></el-input>
      </el-form-item> -->

      <el-form-item label="立项时间" prop="lixiangtime">
        <el-input type="text" v-model="form.lixiangtime" placeholder="立项时间"></el-input>
      </el-form-item>

      <el-form-item label="募捐编号" prop="mujuanbianhao">
        <el-input type="text" v-model="form.mujuanbianhao" placeholder="募捐编号"></el-input>
      </el-form-item>


      <el-form-item label="项目主图" prop="img">

        <div class="block" style="width: 400px;height: 300px;overflow: hidden;">
          <el-image :src="src" fit="fill"></el-image>
        </div>

        <el-input type="text" v-model="form.img" placeholder="可以点击下面按钮上传图片也可以直接输入图片地址"
          @change="change('img',form.img)"></el-input>
        <div style="display: flex;justify-content: space-between;margin: 10px;">
          <el-upload ref="upload" class="" action="none" :limit="1" :file-list="fileList" list-type="picture"
            :show-file-list="false" :http-request="handleFileUpload"><el-button type="success"
              size='medium'>上传图片</el-button>
          </el-upload>
          <el-button type="primary" size='small'>从素材素选取</el-button>
          </el-upload>
        </div>

      </el-form-item>






      <el-form-item label="项目详情" prop="content">
        <editor :content="content" @getcontent="getcontent"> </editor>
      </el-form-item>








      <div style="display: flex; justify-content: flex-end;">
        <el-button type="primary" @click="onSubmit">提 交</el-button>
        <el-button @click="clear">重 置</el-button>
      </div>
    </el-form>


    <el-upload class="avatar-uploader" action="http://www.csgyzc.cn/api/cishangapi/imgupload/save" name="file"
      :show-file-list="false" :on-success="Success" :on-error="Error">
    </el-upload> 
  </div>
</template>

<script>
	import {
		Loading
	} from 'element-ui';
	import editor from '@/components/editor.vue'
	export default {
		components: {
			editor //这里是组件中name名称，在import引入的时候名称要一致
		},
		data() {
			return {
				src: '',
				fileList: [],
				desc: '',
				labelPosition: 'left',
				form: {
					name: '',
					title: '',
					text: '',
					appname: this.$appname
				},
				menulist: [],
				activeNames: ['1'],
				content: '请输入项目详情'
			}
		},
		created() {
			const formData = new FormData()
			formData.append('appname', this.$appname)
			this.get_menu(formData)
		},
		methods: {
			getcontent(e) {
				console.log(e);
				this.desc = e
			},
			clear() {
				this.$refs.form.resetFields()
				this.content = ''
			},
			change(name, val) {
				console.log(name);
				if (name == 'img') {
					this.src = val
				}
			},
			// 上传前的钩子
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg'
				const isLt2M = file.size / 1024 / 1024 < 2

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!')
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!')
				}
				return isJPG && isLt2M
			},
			async handleFileUpload(param) {
				// 3. 生成动画
				this.loadingInstance = Loading.service({
					// 动画中的文字
					text: '文件上传中,请稍等！',
					lock: true, // 是否锁屏
					// 要加载动画的容器
					target: '.app-container'
				});
				//传参进来，同时定义一个formdata对象
				const formData = new FormData()
				formData.append('name', 'file')
				formData.append('file', param.file)

				const {
					data: res
				} = await this.$http.post('api/imgupload/save', formData)
				console.log(res)
				console.log(res.result);
				if (res.errorCode == '200') {
					this.loadingInstance.close();
					this.form.img = this.$imgapi + res.result
					this.$message('图片上传成功');
					this.src = this.$imgapi + res.result
					console.log(this.src);
				}
				this.fileList = []
			},

			onSubmit() {
				this.$confirm('此操作将添加新内容, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(this.form);
					this.loadingInstance = Loading.service({
						// 动画中的文字
						text: '数据正在提交，请稍候！',
						lock: true, // 是否锁屏
						// 要加载动画的容器
						target: '.app-container'
					});
					const formData = new FormData()
					formData.append('appname', this.$appname)
					formData.append('title', this.form.title)
					formData.append('smalltitle', this.form.smalltitle)
					formData.append('text', this.desc)
					formData.append('img', this.src)
					formData.append('yusuan', this.form.yusuan)
					formData.append('yimuji', this.form.yimuji)
					formData.append('juankuanrenci', this.form.juankuanrenci)
					formData.append('faqijigou', this.$appname)
					formData.append('lixiangtime', this.form.lixiangtime)
					formData.append('mujuanbianhao', this.form.mujuanbianhao)
					formData.append('lingyu', this.form.name)

					// if (this.desc == '' || this.form.title == '' || this.form.name == '') {
					// 	this.loadingInstance.close();
					// 	this.$message('提交数据不完整！');
					// 	return
					// }



					this.post(formData)
					setTimeout(() => {
						this.loadingInstance.close();
					}, 500)
				}).catch(() => {

				});

			},

			async post(formData) {
				const result = await this.$http.post('api/content/add', formData)
				console.log(result);
				switch (result.data.data.message) {
					case '提交成功':
						// 清空表单
						this.$refs.form.resetFields()
						this.$message(result.data.message);
						break;
					case '无操作权限':
						this.$message(result.data.message);
						break;
					default:
						this.$message(result.data.message);
						break;
				}

			},
			async get_menu(formData) {
				const result = await this.$http.post('api/menu/get_menu', formData)
				console.log(result.data.data);
				switch (result.data.message) {
					case '数据已拉取':
						this.menulist = result.data.data
						break;
					case '无操作权限':
						this.$message(result.data.message);
						break;
					default:
						this.$message(result.data.message);
						break;
				}
			},
			handleChange(e) {
				console.log(e);
			},
			Success(res, file) {
				// 获取实例
				let quill = this.$refs.myQuillEditor.quill
				console.log(res);
				// 上传成功
				if (res.errorCode == 200) { //res.errorCode是上传是否成功的状态值~~res.result是上传成功返回的图片路径
					// 获取官标位置
					console.log(res)
					let cursor = quill.getSelection().index;

					//我这里loot.SERVE.file是服务器前半截地址加上res.result返回的半截地址
					quill.insertEmbed(cursor, 'image', this.$imgapi + res.result)
					// 光标向后加1
					quill.setSelection(cursor + 1)
				} else {
					this.$message.error('上传失败')
				}
			},
			Error() {
				this.$message.error('上传失败')
			},
			onEditorReady(editor) {}, // 准备编辑器
			onEditorBlur() {}, // 失去焦点事件
			onEditorFocus() {}, // 获得焦点事件
			onEditorChange() {}, // 内容改变事件
			saveHtml(event) {
				alert(this.content)
			}
		}
	}
</script>