<template>
	<el-container class="home">
		<!--   页面头部开始 -->

		<el-header>
			<div style="display: flex;align-items: center;padding: 20px;"><img class="logo" :src="appdesc.logo" />
				<span class="title"> {{appname}}</span>
			</div>

			<div>
				<i class="el-icon-user-solid"
					style="font-size: 15px;align-items: center;text-align: center;margin-right: 20px;font-weight: 900;">
					{{user}}</i>
				<el-button @click="logout" type="primary"> 退出</el-button>
			</div>
		</el-header>
		<!-- 页面头部结束 -->
		<!-- 左铡菜单开始 -->
		<el-container class="home">
			<el-menu class="" @open="handleOpen" @close="handleClose" active-text-color="#409EEE"
				style="width: 280px;height: 95vh;" unique-opened router :default-active="NavState"
				background-color="#2d3133" text-color="#eef0f2">

				<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-s-platform"></i>
						<span>应用管理</span>
					</template>
					<el-menu-item-group style="margin-left: 10px;">
						<el-menu-item index="/appDetails" @click="saveNavState('/appDetails')">应用详情</el-menu-item>
					</el-menu-item-group>
				</el-submenu>

				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-date"></i>
						<span>项目管理</span>
					</template>
					<el-menu-item-group style="margin-left: 10px;">
						<el-menu-item index="/contentlist" @click="saveNavState('/contentlist')">项目列表</el-menu-item>
						<el-menu-item index="/contentadd" @click="saveNavState('/contentadd')">项目添加</el-menu-item>
					</el-menu-item-group>
				</el-submenu>

				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-s-shop"></i>
						<span>运营管理</span>
					</template>
					<el-menu-item-group style="margin-left: 10px;">
						<el-menu-item index="/shoukuanlist" @click="saveNavState('/shoukuanlist')">交易明细</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
			</el-menu>
			<!-- 左铡菜单结束 -->

			<!-- 主体开始 -->
			<el-main style="">
				<div class="infinite-list" v-infinite-scroll="load"
					style="overflow:auto;height: 90vh;position: relative;" id="fullscreen-scroll">
					<router-view></router-view>
				</div>
			</el-main>
			<!-- 主体结束 -->
		</el-container>
	</el-container>
</template>


<script>
	export default {
		data() {
			const item = {};
			return {
				appdesc: '',
				logo: window.sessionStorage.getItem('logo'),
				appname: window.sessionStorage.getItem('appname'),
				isopen: true,
				user: window.sessionStorage.getItem('tel'),
				tableData: Array(20).fill(item),
				NavState: window.sessionStorage.getItem('Navstate')
			}
		},
		computed: {
			defaultActive() {
				///return this.$route.path
			}
		},

		async mounted() {
			console.log(333333)
			const  appname=window.sessionStorage.getItem('appname')
			await this.getappdesc(appname)


		}, 

		methods: {
			async getappdesc(appname) {
				const formData = new FormData()
				formData.append('appname',appname)
				const result = await this.$http.post('api/yingyong/getappdesc', formData)
				switch (result.data.message) {
					case '获取数据成功':
						this.appdesc = result.data.data
						console.log(this.appdesc);
						window.sessionStorage.setItem('appdesc', this.appdesc)
						break;
					case '无操作权限':
						break;
					default:
						this.$message(result.data.message);
						break;
				}
			},
			refreshPage() {
				window.location.reload();
			},
			yingcangmenu() {
				this.isopen = !this.isopen
				console.log(this.isopen);
			},
			logout() {
				sessionStorage.removeItem('tel');
				this.$router.push('/login')
			},
			saveNavState(NavState) {
				this.NavState = NavState
				window.sessionStorage.setItem('Navstate', NavState)
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}

	};
</script>

<style>
	.el-header {
		display: flex;
		justify-content: space-between;
		background-color: #2d3133;
		align-items: center;
		color: #fff;
	}

	.logo {
		width: 30px;
		height: 30px;
		border: 1px solid #eee;
		border-radius: 100%;
		padding: 5px;
		-webkit-box-shadow: 0 0 10px #ddd;
	}

	.title {
		font-size: 15px;
		color: #fff;
		margin-left: 20px;
		font-weight: 900;
	}

	.el-aside {
		color: #333;
	}

	.el-menu {
		border-right: none;
	}
</style>