<template>
  　<div>
    <el-upload class="avatar-uploader" action="https://www.csgyzc.cn/cishangapi/imgupload/save" name="file"
      :show-file-list="false" :on-success="Success" :on-error="Error">
    </el-upload>
    <quill-editor v-model="newcontent" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)" @change="onEditorChange($event)" @ready="onEditorReady($event)"></quill-editor>

  </div>
</template>

<script>

  export default {
    props: {
      content: {
        type: String,
        default: null

      }
    },

    data() {
      return {
        activeNames: ['1'],
        newcontent: this.content,
        editorOption: {
          theme: 'snow', //默认配置项
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进
              [{
                direction: 'rtl'
              }], // 文本方向
              [{
                size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36']
              }], // 字体大小
              [{
                header: [1, 2, 3, 4, 5, 6]
              }], // 标题
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{
                align: []
              }], // 对齐方式
              ['clean'], // 清除文本格式
              ['image', 'video'] // 链接、图片、视频
            ],
          },

          placeholder: '请输入正文',

          // 上传图片时modules中内容如下
          modules: {
            toolbar: {
              container: [
                //配置项，全部配置如上
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['blockquote', 'code-block'], // 引用  代码块

                [{
                  list: 'ordered'
                }, {
                  list: 'bullet'
                }], // 有序、无序列表
                [{
                  script: 'sub'
                }, {
                  script: 'super'
                }], // 上标/下标
                [{
                  indent: '-1'
                }, {
                  indent: '+1'
                }], // 缩进
                [{
                  direction: 'rtl'
                }], // 文本方向
                [{
                  size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36']
                }], // 字体大小
                [{
                  header: [1, 2, 3, 4, 5, 6]
                }], // 标题
                [{
                  color: []
                }, {
                  background: []
                }], // 字体颜色、字体背景颜色
                [{
                  align: []
                }], // 对齐方式
                ['image']
              ],
              handlers: {
                'image': function(value) {
                  if (value) {
                    document.querySelector('.avatar-uploader input').click()
                  } else {
                    this.quill.format('image', false);
                  }
                }
              }
            }
          }
        },
      }
    },

    watch:{
    	content : function(v){
    		console.log(v);
    		this.newcontent = v;
    		//this.init();
    	}
    },


    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    methods: {

      handleChange() {},
      Success(res, file) {
        // 获取实例
        let quill = this.$refs.myQuillEditor.quill
        console.log(res);
        // 上传成功
        if (res.errorCode == 200) { //res.errorCode是上传是否成功的状态值~~res.result是上传成功返回的图片路径
          // 获取官标位置
          console.log(res)
          let cursor = quill.getSelection().index;

          //我这里loot.SERVE.file是服务器前半截地址加上res.result返回的半截地址
          quill.insertEmbed(cursor, 'image', this.$imgapi + res.result)
          // 光标向后加1
          quill.setSelection(cursor + 1)
        } else {
          this.$message.error('上传失败')
        }
      },
      Error() {
        this.$message.error('上传失败')
      },
      onEditorReady(editor) {}, // 准备编辑器
      onEditorBlur() {}, // 失去焦点事件
      onEditorFocus() {}, // 获得焦点事件
      onEditorChange() {
        this.$emit('getcontent', this.newcontent);
      }, // 内容改变事件
      saveHtml(event) {
        alert(this.newcontent)
      }
    }
  }
</script>
