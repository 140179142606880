<template>
	<div class="container">

		<!-- <div style="">
			<div style="height: 20px;"></div> 
			<div style="display: flex;justify-content: space-between;margin: 20px;">
				<transition name="el-zoom-in-center">
					<div v-show="show2" class="transition-box">.el-zoom-in-center</div>
				</transition>

				<transition name="el-zoom-in-top">
					<div v-show="show2" class="transition-box">.el-zoom-in-top</div>
				</transition>

				<transition name="el-zoom-in-bottom">
					<div v-show="show2" class="transition-box">.el-zoom-in-bottom</div>
				</transition>
			</div>
		</div> -->
		<div class=" ">
			<div class="login_box">
				<div class="avatar_box" style="">
					<img :src="logo" alt="" />
				</div>
				<a id="ariaTipText" role="pagedescription" aria-label="" href="javascript:void(0)"></a>
				<el-form ref="loginFormRef" label-width="0px" class="login_form" :model="loginForm"
					:rules="loginFormRules">

					<!-- 账号密码区 -->
					<el-form-item prop="appname">
						<el-input prefix-icon="iconfont icon-danju" v-model="loginForm.appname">小程序名称</el-input>
					</el-form-item>
					<!-- 账号密码区 -->
					<el-form-item prop="tel">
						<el-input prefix-icon="iconfont icon-user" v-model="loginForm.tel">手机号
						</el-input>
					</el-form-item>

					<el-form-item prop="password">
						<el-input prefix-icon="iconfont icon-3702mima" type="password" v-model="loginForm.password"
							prop="password">密码
						</el-input>
					</el-form-item>
					<el-form-item class="botton">
						<el-button type="success" @click="onSubmit"> 登 陆</el-button>
						<el-button type="danger" @click="resetLoginForm">重 置</el-button>
					</el-form-item>
				</el-form>
				<!-- 账号密码区 -->
			</div>
			<div class="title" style="font-size: 20px;">
				技术支持:慈善公益之窗 | <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">闽ICP备2023016575号-3</a>
			</div>



		</div>
	</div>
</template>

<script>
	import {
		setTimeout
	} from 'core-js';
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				appname: window.sessionStorage.getItem('appname'),
				show2: false,
				logo: window.sessionStorage.getItem('logo'),
				tel: '',
				loginForm: {
					appname: window.sessionStorage.getItem('appname'),
					tel: '',
					password: ''
				},
				/* 验证规则 */
				loginFormRules: {
					appname: [{
							required: true,
							message: "请输入小程序名称",
							trigger: 'blur'
						},
						{
							min: 4,
							max: 120,
							message: '请输入正确的小程序名称',
							trigger: 'blur'
						}
					],
					tel: [{
							required: true,
							message: "请输入手机号码",
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '手机号长度要求11位',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: "请输入登陆密码",
							trigger: 'blur'
						},
						{
							min: 6,
							max: 11,
							message: '密码长度要求6-11位',
							trigger: 'blur'
						}
					]
				},
				/* 验证规则 */
			}
		},
		onLoad() {


		},
		mounted() {
			this.logo = window.sessionStorage.getItem('logo')
			this.$appname = window.sessionStorage.getItem('appname')
			this.tel = sessionStorage.getItem('tel');
			this.$tel = this.tel
			this.loginForm = {
				appname: window.sessionStorage.getItem('appname'),
				tel: this.tel,
				password: ''
			}
			
			var that = this
			setTimeout(function() {
				that.click()
			}, 1000); //三秒之后执行函数  
		},
		created() {
		
		},
		methods: {
			click() {
				this.show2 = !this.show2
			},
			onSubmit() {
				this.show2 = true
				// 表单验证
				this.$refs.loginFormRef.validate(async (valid) => {
					console.log(valid);
					if (!valid) return;
					this.loadingInstance = Loading.service({
						// 动画中的文字
						text: '正在登录验证,请稍候...',
						lock: true, // 是否锁屏
						// 要加载动画的容器
						target: '.app-container'
					});
					const formData = new FormData()
					formData.append('tel', this.loginForm.tel)
					formData.append('password', this.loginForm.password)
					formData.append('appname', this.loginForm.appname) 
					const result = await this.$http.post('api/login/tellogin', formData)
					switch (result.data.message) {
						case '登录成功':
							window.sessionStorage.setItem('tel', result.data.data.tel)
							this.$message('用户' + result.data.data.tel + '登录成功');
							window.sessionStorage.setItem('appname', result.data.data.appname)
							this.$appname = window.sessionStorage.getItem('appname')
							console.log(this.$appname)
							window.sessionStorage.setItem('logo', result.data.data.logo)
							this.$logo = window.sessionStorage.getItem('logo')
							var that=this
							setTimeout(() => {
								that.loadingInstance.close();
								that.$router.push('/index')
							}, 500)
							
							
						 

							
							break;
						case '无此账户信息':
							this.$message(result.data.message);
							break;
						default:
							this.$message(result.data.message);
							break;
					}

					setTimeout(() => {
						this.loadingInstance.close();
					}, 500)

				})
			},
			resetLoginForm() {
				// 清空表单
				this.$refs.loginFormRef.resetFields()
			},
			async get_allimage() {
				const result = await this.$http.post('api/admin/imageupload/getimageall', '')
				this.result = result.data
			},


			async getappdesc() {
				const formData = new FormData()
				formData.append('appname', this.$appname)
				const result = await this.$http.post('api//yingyong/getappdesc', formData)
				switch (result.data.message) {
					case '获取数据成功':
						this.appdesc = result.data.data
						console.log(this.appdesc);
						window.sessionStorage.setItem('appdesc', this.appdesc)
						break;
					case '无操作权限':
						break;
					default:
						this.$message(result.data.message);
						break;
				}
			}


		}

	}
</script>

<style lang="less" scoped>
	.login_container {}



	/*   .btnFalses{
        background-color: #022F92
    }*/

	.login_box {
		width: 300px;
		height: 380px;
		background-color: #fff;

		border-radius: 3px;
		position: absolute;
		right: 10%;
		bottom: 10%;

		// transform: translate(-50%, -50%);

		///图标
		.avatar_box {
			width: 130px;
			height: 130px;
			border: 1px solid #eee;
			border-radius: 50%;
			padding: 10px;
			-webkit-box-shadow: 0 0 10px #ddd;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%); //移动本身的比例
			background-color: #fff;

			img {

				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #eee;
			}
		}

		///图标
		.login_form {

			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 0 20px;
			box-sizing: border-box; //防止元素超出
		}

		.botton {
			margin-top: 20px;
			display: flex;
			justify-content: flex-end;
		}




	}

	.title {
		position: absolute;
		left: 20px;
		bottom: 20px;
		font-size: 8px;
		color: #ddd
	}

	.container {
		background-image: url('https://pcs4.clubstatic.lenovo.com.cn/data/attachment/forum/201602/21/215849ddpnrs1n4zkdu8rr.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 100vh;
	}

	.transition-box {
		width: 30vw;
		height: 260px;
		border-radius: 4px;
		background-color: #409EFF;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
	}
</style>