<template>
  <div style="height: 100vh;">

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>应用管理</el-breadcrumb-item>
      <el-breadcrumb-item>应用详情</el-breadcrumb-item>
    </el-breadcrumb> 
	
	<div style="height: 20px;"></div>
    <el-form ref="form" :model="form" :label-position="labelPosition" :rules="FormRules" label-width="120px"> 
      <el-form-item label="成立时间" prop="chenglitime">
        <el-input v-model="form.chenglitime"></el-input>
      </el-form-item>
      <el-form-item label="规模" prop="guimo">
        <el-input v-model="form.guimo"></el-input>
      </el-form-item>

      <el-form-item label="联系地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="lianxiren">
        <el-input v-model="form.lianxiren"></el-input>
      </el-form-item>

      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>




   <!--   <el-form-item label="小程序APPid" prop="appid">
        <el-input v-model="form.appid"></el-input>
      </el-form-item> -->


      <!-- <el-form-item label="小程序AppSecret" prop="AppSecret">
        <el-input v-model="form.AppSecret"></el-input>
        
      </el-form-item>



      <el-form-item label="商户号ID" prop="shanghu_id">
        <el-input v-model="form.shanghu_id"></el-input>
      </el-form-item> -->



      <el-form-item label="总会简介" prop="content">
        <editor :content="content" @getcontent="getcontent"> </editor>
      </el-form-item>

<!--      <el-form-item label="是否上线">
        <el-switch v-model="form.inline"></el-switch>
      </el-form-item>
      <el-form-item> -->



        <div style="position: fixed;right: 20px;bottom: 20px;;">
          <el-button type="primary" @click="onSubmit">提 交</el-button>
          <el-button>重 置</el-button>
        </div>

      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import {
    Loading
  } from 'element-ui';
  import editor from '@/components/editor.vue'
  export default {
    components: {
      editor //这里是组件中name名称，在import引入的时候名称要一致
    },
    data() {
      return {
        content: 'null',
        activeNames: ['1'],
		appname :window.sessionStorage.getItem('appname') ,
        labelPosition: 'left',
        desc: '',
        form: {
          name: '',
          desc: '',
          appid: '',
          AppSecret: '',
          shanghu_id: '',
          inline: true
        },
        /* 验证规则 */
        FormRules: {
          // appid: [{
          //     required: true,
          //     message: "请输入小程序appid",
          //     trigger: 'blur'
          //   },
          //   {
          //     min: 6,
          //     max: 20,
          //     message: '长度6-11',
          //     trigger: 'blur'
          //   }
          // ],
          // AppSecret: [{
          //     required: true,
          //     message: "请输入小程序AppSecret",
          //     trigger: 'blur'
          //   },
          //   {
          //     min: 6,
          //     max: 20,
          //     message: '长度6-11',
          //     trigger: 'blur'
          //   }
          // ],

          // shanghu_id: [{
          //     required: true,
          //     message: "请输入已关联商户ID",
          //     trigger: 'blur'
          //   },
          //   {
          //     min: 6,
          //     max: 20,
          //     message: '长度6-11',
          //     trigger: 'blur'
          //   }
          // ],
        },
        /* 验证规则 */
      }
    },

    async created() {
      this.loadingInstance = Loading.service({
        // 动画中的文字
        text: '数据获取中...',
        lock: true, // 是否锁屏
        // 要加载动画的容器
        target: '.app-container',
        spinner: 'el-icon-loading', // 自定义图标
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const formData = new FormData()
        formData.append('appname',  window.sessionStorage.getItem('appname'))
      const result = await this.$http.post('api/yingyong/getappdesc', formData)
      switch (result.data.message) {
        case '获取数据成功':
          this.appdesc = result.data.data
          console.log(this.appdesc);
          this.form = this.appdesc
          this.content = this.appdesc.content
          // this.form.appid = this.appdesc.appid
          // this.form.AppSecret = this.appdesc.AppSecret
          // this.form.shanghu_id = this.appdesc.shanghu_id
          // this.form.inline = this.appdesc.true
 
          this.loadingInstance.close();
          break;
        case '无操作权限':
          break;
        default:
          this.$message(result.data.message);
          break;
      }



    },




    methods: {
      getcontent(e) {
        console.log(e);
        this.desc = e
      },

      handleChange() {

      },
      onSubmit() {
        console.log(this.form);
        // 表单验证
        this.$refs.form.validate(async (valid) => {
          console.log(valid);
          if (!valid) return;

          this.loadingInstance = Loading.service({
            // 动画中的文字
            text: '数据正在提交，请稍候！',
            lock: true, // 是否锁屏
            // 要加载动画的容器
            target: '.app-container'
          });
          const formData = new FormData()
          formData.append('appname',  window.sessionStorage.getItem('appname'))
          formData.append('content', this.desc)
          formData.append('appid', this.form.appid)
          formData.append('AppSecret', this.form.AppSecret)
          formData.append('shanghu_id', this.form.shanghu_id)
          formData.append('inline', this.form.inline)
          formData.append('username', this.$tel)
          formData.append('xiangmuzongshu', this.form.xiangmuzongshu)
          formData.append('yimujinger', this.form.yimujinger)
          formData.append('juankuanrenshu', this.form.juankuanrenshu)
          formData.append('chenglitime', this.form.chenglitime)
          formData.append('guimo', this.form.guimo)
          formData.append('address', this.form.address)
          formData.append('lianxiren', this.form.lianxiren)
          formData.append('tel', this.form.tel)
          formData.append('email', this.form.email)
          // formData.append('emai', this.form.emai)
          //     formData.append('emai', this.form.emai)
          //         formData.append('emai', this.form.emai)
          //             formData.append('emai', this.form.emai) 
          const result = await this.$http.post('api/yingyong/add', formData)
          console.log(result);
          switch (result.data.data.message) {
            case '提交成功':
              break;
            case '无操作权限':
              break;
            default:
              this.$message(result.data.message);
              break;
          }

        })

        setTimeout(() => {
          this.loadingInstance.close();
        }, 500)






      }
    }
  }
</script>