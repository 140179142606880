<template>
  <div>
    <!-- 导航栏开始 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>用户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!--   导航栏结束 -->

    <el-descriptions title="" direction="vertical" :column="8" border style="margin-top: 20px;" size='mini'>
      <el-descriptions-item label="用户ID" align="center">{{userdesc.id}}</el-descriptions-item>
      <el-descriptions-item label="邀请人ID" align="center">{{userdesc.yaoqingrenid}}</el-descriptions-item>
      <el-descriptions-item label="用户名" align="center">{{userdesc.username}}</el-descriptions-item>
      <el-descriptions-item label="手机号" align="center">{{userdesc.tel}}</el-descriptions-item>
      <el-descriptions-item label="VIP等级" :span="2" align="center">{{userdesc.vip}}</el-descriptions-item>
      <el-descriptions-item label="邮箱" align="center">{{userdesc.email}}</el-descriptions-item>
      <el-descriptions-item label="联系地址" align="center">{{userdesc.add}}</el-descriptions-item>
    </el-descriptions>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="创建的相册" name="创建的相册">创建的相册</el-tab-pane>
      <el-tab-pane label="参与的相册" name="参与的相册">参与的相册</el-tab-pane>
      <el-tab-pane label="收藏的图片" name="收藏的图片">收藏的图片</el-tab-pane>
      <el-tab-pane label="精修的图片" name="精修的图片">精修的图片</el-tab-pane>
      <el-tab-pane label="入册设计" name="入册设计">入册设计</el-tab-pane>
    </el-tabs>

    <div class="image_item" v-if="activeName=='收藏的图片'" style="margin-top: 20px;">
      <div class="imgbox" v-for="(item, key) in imglist" :key="key">

        <el-image  :src="item.img" @click="bigimg(item)" type="primary":fit='fit'>
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>

        <div class="bianhao"> ID:{{key+1}} </div>
        <div class="bianhao"> 文件名:{{item.imgname}} </div>
        <div style="margin-top: -30px;"></div>




        <el-row style="display: flex;justify-content:space-between; align-items: center; height: 30px;">
          <el-upload ref="upload" class="" :data="{'index':key,'img':item.img}" action="none" :limit="1"
            :file-list="fileList" list-type="picture" :show-file-list="false" :http-request="handleFileUpload">
            <el-button type="warning" size='mini' v-if="item.jingxiutu==0">精修上传</el-button>
            <el-button type="primary" size='mini' v-else>已精修 </el-button>
          </el-upload>
          <el-button type="success" size='mini' @click="beizhu(item.id)">
            信息修改</el-button>
        </el-row>




        <div style="height: 10px;"> </div>
      </div>
    </div>

    <div class="image_item" v-if="activeName=='精修的图片'" style="margin-top: 20px;">

      <div class="imgbox" v-for="(item, key) in imglist" :key="key">

        <el-image :src="imgapi + item.jingxiutu" @click="bigjingxiuimg(imgapi+item.jingxiutu)" type="primary" :fit='fit'>
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>

        <div class="bianhao"> ID:{{key+1}} </div>
        <div class="bianhao"> 文件名:{{item.imgname}} </div>
        <div style="margin-top: -30px;"></div>




        <el-row style="display: flex;justify-content:space-between; align-items: center; height: 30px;">
          <el-button type="primary" size='mini'>重修说明</el-button>
          </el-upload>
          <el-button type="success" size='mini' @click="beizhu(item.id)">
            入册设计</el-button>
        </el-row>




        <div style="height: 10px;"> </div>
      </div>



    </div>







    <el-drawer title="警告:以下仅是大图展示,并非原图" :visible.sync="drawer" :direction="direction" :before-close="handleCloseport"
      :size="900" close-on-press-escape>



      <div class="demo-image__placeholder">
        <div class="block">
          <el-image :src="src"
            style="display: flex;justify-content: center;align-items: center;text-align: center;width: 100%;">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
    </el-drawer>



  </div>
</template>

<script>
  import {
    Loading
  } from 'element-ui';
  export default {
    data() {
      return {
        drawer: false,
        direction: 'ttb',
        src: '',
        fit: 'contain',
        userdesc: JSON.parse(window.sessionStorage.getItem('userdesc')),
        activeName: '创建的相册',
        imglist: [],
        fileList: [],
        imgapi:''
      }
    },
    computed: {},

    async created() {
      this.imgapi=this.$imgapi
      console.log(this.imgapi);

      const formData = new FormData()
      formData.append('tel', this.userdesc.tel)
      formData.append('albumid', 0)
      const result = await this.$http.post('api/imgshoucang/get_shoucang', formData)
      this.imglist = result.data.data
      console.log(this.imglist);
    },
    methods: {
      bigimg(item) {
        this.drawer = true 
        if (item.jingxiutu == 0) {
          this.src = item.img
        } else {
          this.src = this.imgapi+item.jingxiutu
        }
      },
      bigjingxiuimg(img) {
        this.drawer = true
        this.src =img
      },


      handleClick(tab, event) {
        console.log(this.activeName);
      },
      // 上传前的钩子
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!')
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG && isLt2M
      },
      // 头像自定义上传
      async handleFileUpload(param) {
        // 3. 生成动画
        this.loadingInstance = Loading.service({
          // 动画中的文字
          text: '文件上传中,请稍等！',
          lock: true, // 是否锁屏
          // 要加载动画的容器
          target: '.app-container'
        });
        //传参进来，同时定义一个formdata对象
        const formData = new FormData()
        formData.append('name', 'file')
        formData.append('file', param.file)

        const {
          data: res
        } = await this.$http.post('admin/image', formData)
        console.log(res)
        console.log(res.data);


        if (res.message == 'OK') {
          this.loadingInstance.close();
          this.$message('图片上传成功');
          this.imglist[param.data.index].jingxiutu = res.data
          this.jingxiu_add(param.data.img, res.data)

        }
        this.fileList = [] 
      },

      async jingxiu_add(img, jingxiutu) {
        this.loadingInstance = Loading.service({
          // 动画中的文字
          text: '数据正在更新,请稍等！',
          lock: true, // 是否锁屏
          // 要加载动画的容器
          target: '.app-container'
        });
        console.log(img);
        const formData = new FormData()
        formData.append('img', img)
        formData.append('jingxiutu', jingxiutu)
        const result = await this.$http.post('api/imgshoucang/set_jingxiu', formData)
        switch (result.data.message) {
          case '更新成功':
            this.$message('更新成功');
            this.loadingInstance.close();
            this.fileList = []
            break;

          default:
            this.$message(result.data.message);
            break;
        }



        console.log(result);



      },








      handleCloseport(done) {
        done();

        // this.$confirm('确认关闭？')
        //   .then(_ => {
        //     done();
        //   })
        //   .catch(_ => {});
      },
      onexceed(e) {
        console.log(e);
      }


    }



  }
</script>

<style lang="less">
  .el-tabs {
    margin-top: 20px;
  }

  .image_item {

    margin-top: 30px;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;



    .imgbox {

      margin: 1px;
      background-color: #ddd;


    }

    .bianhao {
      position: relative;
      bottom: 150px;
      width: 200px;
      left: 15px;
      color: aliceblue;
      font-size: 10px;
    }



    .el-image {
      margin: 2px;
      width: 200px;
      height: 150px;
    }
  }

  .image-slot {
    font-size: 38px;

  }
</style>
