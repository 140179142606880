<template>

	<div>

		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>运营管理</el-breadcrumb-item>
			<el-breadcrumb-item>交易明细</el-breadcrumb-item>
		</el-breadcrumb>

		<div style="display: flex;justify-content: space-between;">
			<div style="width: 500px;display: flex;justify-content: flex-start;">
				<el-input placeholder="请输入内容" v-model="input3" class="input-with-select" @change="search_change"
					@blur="blur">
					<el-select v-model="select" slot="prepend" placeholder="请选择搜索类型" style="width: 150px;"
						@change="menu_change">
						<el-option label="订单号" value="订单号"></el-option>
						<el-option label="用户ID" value="用户ID"></el-option>
						<el-option label="项目名称" value="项目名称"></el-option>
					</el-select>

				</el-input>
				<div @click="search_star">
					<el-button slot="append" icon="el-icon-search"></el-button>
				</div>
			</div>
			<div style="position: fixed; top: 80px;z-index: 999;right: 10px;200px;">
				<el-row :gutter="5" style="width: 500px;background-color: gainsboro;padding: 20px;display: flex;">
					<el-col :span="12">
						<div>
							<el-statistic :span="12" title="交易金额(元)">
								<template slot="formatter">
									{{orders_feecount}}/{{feecount}}
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :span="12">
						<div>
							<el-statistic :span="12" title="订单量(条)">
								<template slot="formatter">
									{{count}}/{{total}}
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :span="12">
						<div>
							<el-statistic :span="12" title="每页展示(条)">
								<template slot="formatter">
									{{size}}
								</template>
							</el-statistic>
						</div>
					</el-col>

				</el-row>
			</div>

		</div>

		<div style="height: 20px;"></div>

		<el-table :data="contentlist" border style="width: 100%；" highlight-current-row>
			<el-table-column type="index" label="序号" width="80" align="center">
			</el-table-column>
			<el-table-column prop="id" label="id" align="center">
			</el-table-column>
			<el-table-column prop="create_time" label="捐款日期" align="center">
			</el-table-column>
			<el-table-column prop="fee" label="金额(元)" align="center">
				<template slot-scope="scope">
					<div style="">
						{{scope.row.fee/100}}元
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="kaipiao" label="开票状态" align="center">
				<template slot-scope="scope">
					<el-button type="primary" size="small" v-if="scope.row.kaipiao==0">未申请</el-button>
					<el-popover placement="right" width="400" trigger="click" v-if="scope.row.kaipiao==1">
						<el-descriptions title="开票信息" style="" direction="vertical" column="1" border>
							<el-descriptions-item label="开票类型">
								<el-tag size="small" v-if="juankuan_desc.shuihao">公司</el-tag>
								<el-tag size="small" v-else>个人</el-tag>
							</el-descriptions-item>
							<el-descriptions-item label="发票抬头">{{juankuan_desc.title}}</el-descriptions-item>
							<el-descriptions-item label="开票金额">{{juankuan_desc.fee}}</el-descriptions-item>
							<el-descriptions-item label="项目名称">{{juankuan_desc.name}}</el-descriptions-item>
							<el-descriptions-item label="税号"
								v-if="juankuan_desc.shuihao">{{juankuan_desc.shuihao}}</el-descriptions-item>
							<el-descriptions-item label="电话">{{juankuan_desc.tel}}</el-descriptions-item>
							<el-descriptions-item label="地址">{{juankuan_desc.address}}</el-descriptions-item>
						</el-descriptions>
						<div v-if="src">
							<el-image style="width: 100px; height: 100px" :src="src" :fit="fit"></el-image>
						</div>
						<div style="display: flex;margin: 20px;">
							<el-button type="primary" size="small" @click="clickCopy()"
								style="margin-right: 10px;">复制开票信息</el-button>
							<div style="margin-right: 10px;">
								<el-upload ref="upload" class="" action="none" :limit="1" :file-list="fileList"
									list-type="picture" :show-file-list="false"
									:http-request="handleFileUpload"><el-button type="success"
										size='medium'>上传发票</el-button>
								</el-upload>
							</div>
							<div v-if="src">
								<el-button type="warning" size="small" @click="tijiao(juankuan_desc)"
									style="margin-right: 10px;">
									发票无误，确定提交</el-button>
							</div>
						</div>
						<el-button slot="reference" @click="kaipiao(scope.row,scope.$index)" type="warning"
							size="small">查看资料</el-button>
					</el-popover>


					<el-popover placement="right" width="400" trigger="click" v-if="scope.row.kaipiao==2">
						<el-descriptions title="开票信息" style="" direction="vertical" column="1" border>
							<el-descriptions-item label="开票类型">
								<el-tag size="small" v-if="juankuan_desc.shuihao">公司</el-tag>
								<el-tag size="small" v-else>个人</el-tag>
							</el-descriptions-item>
							<el-descriptions-item label="发票抬头">{{juankuan_desc.title}}</el-descriptions-item>
							<el-descriptions-item label="开票金额">{{juankuan_desc.fee}}</el-descriptions-item>
							<el-descriptions-item label="项目名称">{{juankuan_desc.name}}</el-descriptions-item>
							<el-descriptions-item label="税号"
								v-if="juankuan_desc.shuihao">{{juankuan_desc.shuihao}}</el-descriptions-item>
							<el-descriptions-item label="电话">{{juankuan_desc.tel}}</el-descriptions-item>
							<el-descriptions-item label="地址">{{juankuan_desc.address}}</el-descriptions-item>
						</el-descriptions>
						<div v-if="src">
							<div style="margin-bottom: 5px;margin-top: 5px;">发票截图</div>
							<el-image style="width: 100px; height: 100px" :src="src" :fit="fit"
								:preview-src-list="srcList">></el-image>
						</div>


						<div style="display: flex;margin: 20px;">
							<el-button type="primary" size="small" @click="clickCopy()"
								style="margin-right: 10px;">复制开票信息</el-button>
							<div style="margin-right: 10px;">
								<el-upload ref="upload" class="" action="none" :limit="1" :file-list="fileList"
									list-type="picture" :show-file-list="false"
									:http-request="handleFileUpload"><el-button type="success"
										size='medium'>重新上传</el-button>
								</el-upload>
							</div>
							<div v-if="src">
								<el-button type="warning" size="small" @click="tijiao(juankuan_desc)"
									style="margin-right: 10px;">
									发票无误，确定提交</el-button>
							</div>
						</div>
						<el-button slot="reference" @click="kaipiao(scope.row,scope.$index)" type="success"
							size="small">开票完成</el-button>
					</el-popover>


				</template>


			</el-table-column>

			<el-table-column prop="usermsg.username" label="用户昵称" align="center">
			</el-table-column>
			<el-table-column prop="userid" label="用户ID" align="center">
			</el-table-column>

			<el-table-column prop="out_trade_no" label="订单号" align="center">
			</el-table-column>
			<el-table-column prop="name" label="项目名称" align="center">
			</el-table-column>

			<el-table-column fixed="right" label="操作" width="100" align="center">
				<template slot-scope="scope">
					<el-button @click="handleClick(scope.row)" type="success" size="small">查看项目</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 200px;"></div>


		<div style="position: fixed;bottom: 10px;width: 300px;display: flex;justify-content: start;">
			<el-button slot="reference" type="primary" size="medium" @click="download">下载当前页明细</el-button>
			<div style="width: 20px;"></div>
			<el-select v-model="size" slot="ok" placeholder="选择当前页面加载数量"
				style="width: 280px;align-items: center;text-align: center;" @change="page_change">
				<el-option label="1条" value="1"></el-option>
				<el-option label="20条" value="20"></el-option>
				<el-option label="100条" value="100"></el-option>
				<el-option label="10000条" value="10000"></el-option>
			</el-select>

		</div>


		<el-pagination background layout="prev, pager, next" :page-size="size" :total="total" @prev-click="prevclick"
			@next-click="nextclick" @current-change="currentchange">
		</el-pagination>


	</div>
</template>

<script>
	// import XLSX from 'xlsx';
	import * as XLSX from 'xlsx';
	import {
		Loading
	} from 'element-ui';
	export default {
		data() {
			return {
				appname: '',
				formData: '',
				type: '',
				searchvalue: '',
				total: 0,
				feecount: 0,
				like: true,
				value1: 4154.564,
				value2: 1314,
				title: "增长人数",
				input1: '',
				input2: '',
				input3: '',
				select: '',
				select1: '',
				contentlist: [],
				count: 0,
				form: 0,
				size: 10,
				menulist: [],
				value: '',
				msg: [],
				juankuan_desc: '',
				fileList: '',
				src: '',
				index: 0,
				srcList: []
			}
		},
		// 使用示例
		computed: {


		},
		async created() {
			const formData = new FormData()
			formData.append('appname', window.sessionStorage.getItem('appname'))
			formData.append('ispay', 1)
			formData.append('size', this.size)
			await this.get_count(formData)
			await this.get_contents(formData)
		},
		methods: {

			async download() {
				console.log('开始下载')
				 console.log(this.contentlist) 
				const dowunlist = JSON.parse(JSON.stringify(this.contentlist))
				console.log(dowunlist)
				for (var i = 0; i < dowunlist.length; i++) {
					dowunlist[i].username = dowunlist[i].usermsg.username
					dowunlist[i].fee = dowunlist[i].fee / 100
					if (dowunlist[i].kaipiao == 0) {
						dowunlist[i].kaipiao = '未申请发票'
					}
					if (dowunlist[i].kaipiao == 1) {
						dowunlist[i].kaipiao = '已申请发票'
					}
					if (dowunlist[i].kaipiao == 2) {
						dowunlist[i].kaipiao = '已开票'
					} 
				}

				const data = dowunlist.map(item => {
					delete item.chenghu
					delete item.xiangmudesc
					delete item.usermsg
					return item;
				});
				console.log(data)
				// 创建工作簿  
				const workbook = XLSX.utils.book_new();
				// 创建工作表  
				const worksheet = XLSX.utils.json_to_sheet(data);
				// 将工作表添加到工作簿  
				XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
				// 将工作簿保存为Blob对象  
				const blob = new Blob([XLSX.write(workbook, {
					bookType: 'xlsx',
					type: 'array'
				})], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				});
				// 创建下载链接并触发下载操作  
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = '交易记录.xlsx'; // 设置下载文件的文件名  
				document.body.appendChild(link);
				link.click(); // 触发下载操作  
				document.body.removeChild(link);
				this.$message('下载成功')
			},

			download_all() {
				this.$message('当前功能正在快马加鞭地更新中')
			},

			async kaipiao(item, index) {
				this.index = index
				console.log(item);
				const formData = new FormData()
				formData.append('juankuanid', item.id)
				console.log(item);
				const result = await this.$http.post('api/Fapiaos/get_desc', formData)
				switch (result.data.message) {
					case '数据已拉取':
						this.juankuan_desc = result.data.data[0]
						this.src = this.juankuan_desc.url
						this.srcList.push(this.src)
						console.log(this.srcList)
						console.log(this.juankuan_desc)
						break;
					case '无操作权限':
						this.$message(result.data.message);
						break;
					default:
						this.$message('加载错误');
						break;
				}
			},

			async tijiao(item) {
				const formData = new FormData()
				formData.append('id', item.id)
				formData.append('juankuanid', item.juankuanid)
				formData.append('url', this.src)
				const result = await this.$http.post('api/Fapiaos/update', formData)
				switch (result.data.message) {
					case '修改成功':
						this.$message('提交成功');
						this.contentlist[this.index].kaipiao = 2
						this.src = ''
						console.log(this.juankuan_desc)
						break;
					case '无操作权限':
						this.$message(result.data.message);
						break;
					default:
						this.$message('加载错误');
						break;
				}
			},

			clickCopy() {
				var that = this
				console.log(that.juankuan_desc);
				const save = function(e) {
					e.clipboardData.setData('text/plain', 'ID:' + that.juankuan_desc.id + '｜抬头:' + that.juankuan_desc
						.title + "｜税号" + that.juankuan_desc
						.shuihao + "｜项目名称:" + that.juankuan_desc.name + "｜金额:" + that.juankuan_desc.fee +
						'元' + "｜地址:" + that.juankuan_desc.address + "｜电话:" + that.juankuan_desc.tel)
					e.preventDefault() // 阻止默认行为
				}
				document.addEventListener('copy', save) // 添加一个copy事件
				document.execCommand('copy') // 执行copy方法
				this.$message({
					message: '复制成功',
					type: 'success'
				})


			},


			sum(numbers) {
				return numbers.reduce((sum, current) => sum + current, 0);
			},
			menu_change(e) {
				console.log(e)
				if (e == '项目名称') {
					this.type = 'name'
				}
				if (e == '用户ID') {
					this.type = 'userid'
				}
				if (e == '订单号') {
					this.type = 'out_trade_no'
				}

			},

			async page_change(e) {
				console.log(e)
				this.size = e
				console.log(this.size)

				const formData = new FormData()
				formData.append('appname', window.sessionStorage.getItem('appname'))
				formData.append('ispay', 1)
				formData.append('from', -1)
				formData.append('size', this.size)
				await this.get_contents(formData)
			},
			blur(e) {
				if (this.type == '') {
					this.$message('请输入搜索类型');
					return
				}
				if (this.searchvalue == '') {
					this.$message('请输入搜索内容');
					return
				}
				console.log('开始搜索')

			},

			search_change(e) {
				console.log(this.type, this.searchvalue)
				this.searchvalue = e
				console.log(e)
				if (this.type == '') {
					this.$message('请输入搜索类型');
					return
				}
			},
			async search_star() {
				console.log(this.type, this.searchvalue)
				if (this.type == '') {
					this.$message('请输入搜索类型');
					return
				}
				if (this.searchvalue == '') {
					this.$message('没有搜索内容，将为您展示全部');
					const formData = new FormData()
					formData.append('appname', window.sessionStorage.getItem('appname'))
					formData.append('ispay', 1)
					formData.append('from', -1)
					formData.append('size', this.size)
					await this.get_count(formData)
					await this.get_contents(formData)
					return
				} else {
					const formData = new FormData()
					formData.append('appname', window.sessionStorage.getItem('appname'))
					formData.append('ispay', 1)
					formData.append('from', -1)
					formData.append('size', this.size)
					formData.append(this.type, this.searchvalue)
					await this.get_count(formData)
					await this.get_contents(formData)
				}
			},
			prevclick(e) {},
			nextclick(e) {},
			currentchange(e) {},
			async get_contents(formData) {
				this.formData = formData
				const result = await this.$http.post('api/order/get_list_admin', formData)
				switch (result.data.message) {
					case '数据已拉取':
						var orders_feecount = []
						this.contentlist = result.data.data
						for (let i = 0; i < this.contentlist.length; i++) {
							orders_feecount.push(this.contentlist[i].fee)
						}
						this.orders_feecount = this.sum(orders_feecount) / 100
						this.count = this.contentlist.length
						break;
					case '无操作权限':
						this.$message(result.data.message);
						break;
					default:
						this.$message('加载错误');
						break;
				}
			},





			async get_count(formData) {

				const result = await this.$http.post('api/order/get_count', formData)
				this.total = result.data.data.count
				this.feecount = result.data.data.feecount
			},

			dateFormat(row, column) {
				var date = new Date(row.last_logintime) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var Y = date.getFullYear() + '-'
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
				var D = date.getDate() + ' '
				var h = date.getHours() + ':'
				var m = date.getMinutes() + ':'
				var s = date.getSeconds()
				return Y + M + D + h + m + s
			},




			async currentchange(e) {
				if (this.searchvalue != '' && this.searchvalue != '') {
					const formData = new FormData()
					formData.append('appname', window.sessionStorage.getItem('appname'))
					formData.append('ispay', 1)
					formData.append('from', -1)
					formData.append('size', this.size)
					formData.append(this.type, this.searchvalue)
					await this.get_contents(formData)
				} else {
					const formData = new FormData()
					formData.append('appname', window.sessionStorage.getItem('appname'))
					formData.append('ispay', 1)
					formData.append('from', e - 1)
					formData.append('size', this.size)
					await this.get_contents(formData)
				}



			},

			change(e) {},
			seach() {},


			handleClick(row) {




			},

			async handleFileUpload(param) {
				// 3. 生成动画
				this.loadingInstance = Loading.service({
					// 动画中的文字
					text: '文件上传中,请稍等！',
					lock: true, // 是否锁屏
					// 要加载动画的容器
					target: '.app-container'
				});
				//传参进来，同时定义一个formdata对象
				const formData = new FormData()
				formData.append('name', 'file')
				formData.append('file', param.file)
				const {
					data: res
				} = await this.$http.post('api/imgupload/save', formData)
				console.log(res)
				console.log(res.result);
				if (res.errorCode == '200') {
					this.loadingInstance.close();

					this.$message('图片上传成功');
					this.src = this.$imgapi + res.result
					console.log(this.src);
				}
				this.fileList = []
			},








		}

	};
</script>

<style lang="less">
	.el-table {
		text-align: center;
	}

	.like {
		cursor: pointer;
		font-size: 25px;
		display: inline-block;
	}

	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>