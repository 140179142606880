<template>

  <div>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索选择开始 -->
    <el-card class="box-card">
      <el-row style="display: flex;justify-content: space-between;">
        <el-select v-model="value" placeholder="请选择领域" @change="change" size="medium">
          <el-option :label="item.name" :value="item.name" v-for="(item, key) in menulist" :key="key"></el-option>
          </el-option>
        </el-select>




        <div style="width: 100px;"> </div>
        <el-input placeholder="请输入标题关键字查找" v-model="input" class="input-with-select" clearable maxlength="11">
          <el-button slot="append" icon="el-icon-search" @click="seach"></el-button>
        </el-input>
      </el-row>
    </el-card>
    <!-- 搜索选择结束 -->

    <el-table :data="contentlist" border style="width: 100%；">
      <!-- <el-table-column fixed type="index" label="索引" width="80" align="center"></el-table-column> -->
      <el-table-column prop="id" label="id" width="80" align="center">
      </el-table-column>
      <el-table-column prop="lingyu" label="所属领域" width="120" align="center">
      </el-table-column>




      <el-table-column prop="title" label="标题"  align="center">
      </el-table-column>

      <el-table-column prop="faqijigou" label="发起机构" width="120" align="center">
      </el-table-column>

      <el-table-column prop="lixiangtime" label="立项时间" width="120" align="center">
      </el-table-column>
      <el-table-column prop="mujuanbianhao" label="项目编号" width="120" align="center">
      </el-table-column>

      <el-table-column prop="yusuan" label="项目预算" width="120" align="center">
      </el-table-column>


      <el-table-column prop="yimuji" label="已募资金" width="120" align="center">
      </el-table-column>


      <el-table-column prop="juankuanrenci" label="捐款人次" width="120" align="center">
      </el-table-column>



      <el-table-column fixed="right" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="success" size="small">查看</el-button>
          <el-button @click="handleClick(scope.row)" type="primary" size="small">屏蔽</el-button>
          <el-button  type="warning" size="small" >删除</el-button>
        </template>
      </el-table-column>


    </el-table>

    <el-pagination background layout="prev, pager, next" :page-size="size" :total="count"
      @current-change="currentchange">
    </el-pagination>



  </div>
</template>

<script>
  export default {
    data() {
      return {
        contentlist: [],
        count: 0,
        form: 0,
        size: 10,
        menulist: [],
        input: '',
        value: ''
      }
    },
    computed: {},
    async created() {
      const formData = new FormData()
      formData.append('appname',  window.sessionStorage.getItem('appname'))
      await this.get_menu(formData)
      await this.get_contents(formData)
    },
    methods: {
      async get_menu(formData) {
        const result = await this.$http.post('api/menu/get_menu', formData)
        console.log(result.data.data);
        switch (result.data.message) {
          case '数据已拉取':
            this.menulist = result.data.data
            break;
          case '无操作权限':
            this.$message(result.data.message);
            break;
          default:
            this.$message(result.data.message);
            break;
        }
      },
      async get_contents(formData) {
        const result = await this.$http.post('api/content/get_list', formData)
        console.log(result.data.data);
		 this.total = result.data.status
        switch (result.data.message) {
          case '数据已拉取':
            this.contentlist = result.data.data
            this.count = this.contentlist.length
            break;
          case '无操作权限':
            this.$message(result.data.message);
            break;
          default:
            this.$message(result.data.message);
            break;
        }
      },

      dateFormat(row, column) {
        var date = new Date(row.last_logintime) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y + M + D + h + m + s
        console.log(dateFormat(1533293827000))
      },




      async currentchange(e) {
        console.log(e);
        const formData = new FormData()
        formData.append('form', e - 1)
        formData.append('size', this.size)
        const userlist = await this.$http.post('api/user/userlist', formData)
        this.userlist = userlist.data.data.data;




      },

      change(e) {
        console.log(e);
      },
      seach() {
        console.log(this.input);
      },


      handleClick(row) {
        console.log(row);
        // this.$router.push('/userdesc')




      }








    }

  };
</script>

<style lang="less">
  .el-table {
    text-align: center;
  }
</style>
