import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login.vue'
import index from '@/pages/kongzhitai/index.vue'
import welcome from '@/pages/kongzhitai/welcome.vue'
import userlist from '@/pages/user/userlist.vue'
import userdesc from '@/pages/user/userdesc.vue'
import appDetails from '@/pages/app/details.vue'
import banner from '@/pages/app/banner.vue'
import menu from '@/pages/app/menu.vue'
import admin from '@/pages/app/admin.vue'
import version from '@/pages/app/version.vue'
import contentlist from '@/pages/content/contentlist.vue'
import contentadd from '@/pages/content/contentadd.vue'
import shoukuanlist from '@/pages/shoukuan/list.vue'
Vue.use(Router)




const router = new Router({
  routes: [{
      path: '/',
      redirect: '/Login'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/index',
      component: index,
      redirect: '/welcome',
      children: [{
          path: '/welcome',
          component: welcome
        },
        {
          path: '/userlist',
          component: userlist
        },
        {
          path: '/userdesc',
          component: userdesc
        },
        {
          path: '/appDetails',
          component: appDetails
        },
        {
          path: '/banner',
          component: banner
        },
        {
          path: '/menu',
          component: menu
        },
        {
          path: '/admin',
          component: admin
        },
        {
          path: '/version',
          component: version
        },
        {
          path: '/contentlist',
          component: contentlist
        },
        {
          path: '/contentadd',
          component: contentadd
        },
        {
          path: '/shoukuanlist',
          component: shoukuanlist
        }




      ]
    }
  ]
})


// 挂载路由导航守卫

router.beforeEach((to, from, next) => {
  if (to.path == '/login') return next()
  const tel = window.sessionStorage.getItem('tel')
  console.log(tel);
  if (!tel) return next('/login')
  next()
})

export default router







// }
