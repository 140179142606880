<template>

  <div>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索选择开始 -->
    <el-card class="box-card">
      <el-row style="display: flex;justify-content: space-between;">
        <el-select v-model="value" placeholder="选择用户类型" @change="change" size="medium">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div style="width: 100px;"> </div>
        <el-input placeholder="请输入手机号码" v-model="input" class="input-with-select" clearable maxlength="11">

          <el-button slot="append" icon="el-icon-search" @click="seach"></el-button>
        </el-input>
      </el-row>
    </el-card>
    <!-- 搜索选择结束 -->

    <el-table :data="userlist" border stripe style="width: 100%；">
      <el-table-column fixed type="index" label="索引" width="80" align="center"></el-table-column>
      <el-table-column prop="id" label="用户id" width="80" align="center">
      </el-table-column>
      <el-table-column label="头像" width="88" align="center">
        <template slot-scope="scope">
          <img class="staffPhoto" style="width: 38px;width: 38px;" :src="scope.row.avatar">
        </template>
      </el-table-column>
      <el-table-column prop="username" label="昵称" width="120" align="center">
      </el-table-column>

      <el-table-column prop="career " label="职业" width="120" align="center">
      </el-table-column>

      <el-table-column prop="vip" label="VIP等级" width="120" align="center">
      </el-table-column>



      <el-table-column prop="tel" label="手机号" width="120" align="center">
      </el-table-column>

      <el-table-column prop="create_time" label="注册时间" width="170">
      </el-table-column>
      <el-table-column prop="last_logintime" label="最近登录" width="170" :formatter="dateFormat">
      </el-table-column>


      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
          <el-button type="text" size="small">编辑</el-button>
        </template>
      </el-table-column>


    </el-table>
    <el-pagination background layout="prev, pager, next" :page-size="size" :total="count"
      @current-change="currentchange">
    </el-pagination>




  </div>
</template>

<script>
  export default {
    data() {
      return {
        count: 0,
        form: 0,
        size: 10,
        userlist: [],
        input: '',
        value: '全部用户',
        options: [{
            value: '4',
            label: '全部用户'
          },

          {
            value: '3',
            label: '白金VIP'
          }, {
            value: '2',
            label: '黄金VIP'
          }, {
            value: '1',
            label: '普通用户'
          }
        ],
      }
    },
    computed: {},
    async created() {

      //this.userlist = await this.$api('userlist')
      const formData = new FormData()
      formData.append('form', '0')
      formData.append('size', this.size)
      const userlist = await this.$http.post('api/user/userlist', formData)
      this.userlist = userlist.data.data.data;
      this.count = userlist.data.data.count
      console.log(userlist); 
    },
    methods: {
      dateFormat(row, column) {
        var date = new Date(row.last_logintime) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y + M + D + h + m + s
        console.log(dateFormat(1533293827000))
      },




      async currentchange(e) {
        console.log(e);
        const formData = new FormData()
        formData.append('form', e - 1)
        formData.append('size', this.size)
        const userlist = await this.$http.post('api/user/userlist', formData)
        this.userlist = userlist.data.data.data;




      },

      change(e) {
        console.log(e);
      },
      seach() {
        console.log(this.input);
      },


      handleClick(row) {
        console.log(row);
        window.sessionStorage.setItem('userdesc', JSON.stringify(row))
        this.$router.push('/userdesc')




      }








    }

  };
</script>

<style lang="less">
  .el-table {
    text-align: center;
  }
</style>
